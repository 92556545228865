import classNames from 'classnames'

import { If } from 'components/core-ui'
import s from './KeyValuePairs.scss'

const KeyValuePairs = ({
  className = '',
  pairs,
  stackedKeys,
  pairsInline,
  isLoading,
  style = {}
}) => (
  <section
    className={classNames(s.container, { [s.pairsInline]: pairsInline, [className]: className })}
    style={style}
  >
    {pairs.map(
      ({ key, value, style = {}, keyClass = '', valueClass = '', sectionClass = '' }, idx) => (
        <section
          className={classNames(s.pair, { [s.stacked]: stackedKeys, [sectionClass]: sectionClass })}
          style={style}
          key={idx}
        >
          <section className={classNames(s.key, { [keyClass]: keyClass })}>{key}</section>
          {value && (
            <If condition={!isLoading} fallback={<span className={s.empty}>----</span>}>
              <section className={classNames(s.value, { [valueClass]: valueClass })}>
                {value}
              </section>
            </If>
          )}
        </section>
      )
    )}
  </section>
)

export default KeyValuePairs
