import { useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import MaterialTable from '@material-table/core'
import moment from 'moment'
import { VscSearch } from 'react-icons/vsc'
import { getStatusClassName, getStatusLabel } from 'utils/helpers'
import { FiArrowRight } from 'react-icons/fi'
import { toFilterParams, toCasesTableData } from '../ReportsContent/serializers'
import { makeGetRequest } from 'utils/api'
import EllipsisText from 'react-ellipsis-text'
import s from './Table.scss'

const columns = [
  {
    title: 'NUME',
    field: 'name',
    render: rowData => <strong>{rowData.name}</strong>
  },
  {
    title: 'EMAIL',
    field: 'email',
    render: rowData => (
      <a href={`mailto:${rowData.email}`} className={s.link}>
        {rowData.email}
      </a>
    )
  },
  {
    title: 'POD / NLC',
    field: 'distributionCode',
    render: rowData => (
      <span
        style={{
          maxWidth: window.innerWidth / 9 + 'px',
          display: 'inline-block',
          wordWrap: 'break-word'
        }}
      >
        {rowData.distributionCode.code}
      </span>
    )
  },
  {
    title: 'CUI / CNP',
    field: 'userIdentifier',
    render: rowData => <span>{rowData.userIdentifier.code}</span>
  },
  {
    title: 'CATEGORIE',
    field: 'category',
    render: rowData => <span>{rowData.category.name}</span>
  },
  {
    title: 'AGENT ALOCAT',
    field: 'agent',
    render: rowData => <span>{rowData.agent.name}</span>
  },
  {
    title: 'ID SALESFORCE',
    field: 'sf_id',
    render: rowData => <EllipsisText text={rowData.sf_id} length={'10'} />
  },
  {
    title: 'STATUS',
    field: 'status',
    render: rowData => (
      <span
        className={s[getStatusClassName(rowData.status.name, !rowData?.sf_id?.startsWith('D-'))]}
      >
        {getStatusLabel(rowData.status.name, !rowData?.sf_id?.startsWith('D-'))}
      </span>
    )
  },
  {
    title: 'DATA',
    field: 'data',
    render: row => moment(row.data).format('DD.MM.YYYY, HH:mm'),
    defaultSort: 'desc'
  }
]

const Table = ({ filters, setTableQuery }) => {
  const tableRef = useRef()

  const history = useHistory()

  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange()
  }, [filters])

  const fetchData = async query => {
    const result = await makeGetRequest('/api/case', { params: toFilterParams(filters, query) })
    setTableQuery && setTableQuery(query)
    return {
      data: toCasesTableData(result.content),
      page: +result.currentPage,
      totalCount: result.totalCount
    }
  }

  return (
    <div className={s.tableContainer}>
      <MaterialTable
        title=""
        tableRef={tableRef}
        columns={columns}
        data={query => fetchData(query)}
        options={{
          actionsColumnIndex: -1,
          search: false,
          headerStyle: {
            backgroundColor: '#D3D5D6',
            color: '#000',
            position: 'sticky',
            top: 0,
            zIndex: 1,
            fontWeight: 500,
            padding: '1.5rem'
          },
          editCellStyle: {
            maxWidth: window.innerWidth / 9
          },
          filterCellStyle: {
            maxWidth: window.innerWidth / 9
          },
          // maxBodyHeight: '600px',
          pageSize: 20,
          pageSizeOptions: [20, 50, 100],
          padding: 'dense',
          doubleHorizontalScroll: false,
          searchFieldStyle: {
            border: '2px solid #a7a9ab',
            borderRadius: '3px',
            backgroundColor: 'white'
          }
        }}
        localization={{
          toolbar: {
            searchPlaceholder: 'Cauta'
          },
          header: {
            actions: ''
          }
        }}
        icons={{
          Search: () => <VscSearch />
        }}
        style={{
          boxShadow: 'none',
          border: '1px solid #fff',
          backgroundColor: 'rgba(211, 213, 214,0.5)'
        }}
        actions={[
          () => ({
            icon: () => (
              <span className={s.detailsIcon}>
                <FiArrowRight />
              </span>
            ),
            tooltip: 'Detalii',
            onClick: (event, rowData) => history.push(`/case/${rowData.id}`)
          })
        ]}
      />
    </div>
  )
}

export default Table
