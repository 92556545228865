import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import { LoginForm } from './LoginForm'
import { ForgotPassword } from './ForgotPassword'
import { isAuthenticated, logout } from 'utils/authService'
import { loginRedirect } from 'utils/api'
import s from './Login.scss'

const Login = props => {
  const history = useHistory()

  useEffect(() => {
    if (isAuthenticated()) {
      loginRedirect(history)
    } else {
      logout()
    }
  }, [history])

  const [isLoginFormVisible, setIsLoginFormVisible] = useState(true)

  return (
    <>
      <LoginForm
        props={props}
        className={cn({ [s.hidden]: !isLoginFormVisible })}
        setIsLoginFormVisible={setIsLoginFormVisible}
      />
      <ForgotPassword
        className={cn({ [s.visible]: !isLoginFormVisible })}
        setIsLoginFormVisible={setIsLoginFormVisible}
      />
    </>
  )
}

export default Login
