export const getStatusColor = statusName => {
  switch (statusName) {
    case 'active':
      return '#01B747'
    case 'blocked':
      return '#FF2E00'
    default:
      return '#D3D5D6'
  }
}
