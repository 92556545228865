import { LineChart, Line, ResponsiveContainer } from 'recharts'
import { FiArrowDownRight, FiArrowUpRight } from 'react-icons/fi'
import s from './SmallChart.scss'

const SmallCharts = ({ data, color, chartTitle, total, percentage, trend }) => {
  return (
    <div className={s.chartContainer}>
      <div className={s.chartTxt}>
        <h6>{chartTitle}</h6>
        <strong>{total}</strong>
        {trend === 'up' ? (
          <span className={s.green}>
            <FiArrowUpRight />
            {percentage} %
          </span>
        ) : trend === 'down' ? (
          <span className={s.red}>
            <FiArrowDownRight />
            {percentage} %
          </span>
        ) : (
          <span />
        )}
      </div>
      <div className={s.chart}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart width={300} height={100} data={data}>
            <Line type="monotone" dataKey="cases" stroke={color} strokeWidth={2} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default SmallCharts
