import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import s from './Header.scss'
import { URLS } from 'utils/constants'
// import { NotificationsContainer } from 'components/Layout/Header/Notifications'
import { FaBars } from 'react-icons/fa'
import { HeaderMenu } from './HeaderMenu'
import { getUser } from 'utils/authService'
import { TABS } from './constants'
import { getTabByUrl } from './helpers'

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(TABS.DASHBOARD)

  const location = useLocation()

  useEffect(() => {
    setActiveTab(getTabByUrl(location))
  }, [location])

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen)

  const currentUser = getUser()

  return (
    <>
      {/* <NotificationsContainer
        updateUnreadNotifications={updateUnreadNotifications}
        notificationTarget="notificationContainer"
      /> */}
      <div className={s.headerWrapper}>
        <div className={s.headerContainer}>
          {/*closeComponent={() => setIsOpen(false)}>*/}
          <div className={s.logoWrapper}>
            <a className={s.logo} href={URLS.BASE_PATH} />
          </div>
          <div className={s.activeTabWrapper}>
            <h1>{activeTab}</h1>
          </div>
          <div className={s.userGreeting}>
            <p>
              Bine ai venit, {currentUser.firstName} {currentUser.lastName}!
            </p>
            <span onClick={toggleMenu}>
              <FaBars className={s.navbarTogglerIcon} />
            </span>
          </div>
        </div>
      </div>
      <HeaderMenu
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        closeMenu={toggleMenu}
        isVisible={isMenuOpen}
      />
    </>
  )
}

export default Header
