import { useState } from 'react'
import { useImmer } from 'use-immer'
import { Table } from 'components/common/Table'
import { Filters } from 'components/common/Filters'
import { SmallCharts } from './SmallCharts'
import { BigCharts } from './BigCharts'
import { ReportsCharts } from './ReportsCharts'
import cn from 'classnames'
import s from './ReportsContent.scss'
import moment from 'moment'
import { getDatesFromTimespan } from './helpers'
import { toExportParams } from './serializers'

const initialFilter = {
  timeSpan: '7d',
  startDate: moment()
    .subtract(1, 'weeks')
    .toDate(),
  endDate: moment().toDate(),
  category: '',
  agent: '',
  userIdentifierType: '',
  status: '',
  distributionCodeType: '',
  sf_id: '',
  searchQuery: ''
}

const ReportsContent = ({ isHome }) => {
  const [filters, setFilters] = useImmer(initialFilter)
  const [showBigCharts, setShowBigCharts] = useState(false)
  const [tableQuery, setTableQuery] = useState({})

  const onFilterChange = (filter, value) => {
    if (filter === 'timeSpan') {
      setFilters(state => {
        const [startDate, endDate] = getDatesFromTimespan(value)
        state.startDate = startDate
        state.endDate = endDate
      })
    }

    setFilters(state => {
      state[filter] = value
    })
  }

  const searchItems = searchValue => {
    setFilters(state => {
      state.searchQuery = searchValue !== '' ? searchValue : null
    })
  }

  const onFilterRangePickerChange = ({ from, to }) => {
    setFilters(state => {
      state.startDate = from
      state.endDate = to
      state.timeSpan = ''
    })
  }

  return (
    <>
      <Filters
        selectedFilters={filters}
        onFilterChange={onFilterChange}
        onFilterRangePickerChange={onFilterRangePickerChange}
        searchItems={searchItems}
        exportUrl="/api/case/export"
        exportParams={toExportParams(filters, tableQuery)}
      />
      {isHome ? (
        <div className={s.dashboardSlider}>
          <div className={cn(s.dsWrapper, { [s.bigChartsWrapper]: showBigCharts })}>
            <div className={cn(s.dsSlide, { [s.hidden]: showBigCharts })}>
              <SmallCharts setShowBigCharts={setShowBigCharts} filters={filters} />
              <Table filters={filters} />
            </div>
            <div className={cn(s.dsSlide, { [s.hidden]: !showBigCharts })}>
              <BigCharts setShowBigCharts={setShowBigCharts} filters={filters} />
            </div>
          </div>
        </div>
      ) : (
        <div className={s.contentBoxBody}>
          <ReportsCharts filters={filters} />
          <h3 className={s.boxTitle}>Rapoarte detaliate</h3>
          <Table filters={filters} setTableQuery={setTableQuery} />
        </div>
      )}
    </>
  )
}

export default ReportsContent
