import 'regenerator-runtime/runtime'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import configureStore, { history } from './configureStore'

import App from 'components/App/App.js'

import '!style-loader!css-loader!react-day-picker/lib/style.css' // eslint-disable-line

import '!style-loader!css-loader!styles/overrides/react-day-picker.css' // eslint-disable-line

import '!style-loader!css-loader!styles/overrides/css-resetter.css' // eslint-disable-line
import '!style-loader!css-loader!styles/overrides/react-select.css' // eslint-disable-line
import '!style-loader!css-loader!styles/overrides/material-table.css' // eslint-disable-line

const store = configureStore()

const rootEl = document.getElementById('app')

const renderApp = Component => {
  render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Router basename={'/agent'}>
          <Component history={history} />
        </Router>
      </ConnectedRouter>
    </Provider>,
    rootEl
  )
}

renderApp(App)
