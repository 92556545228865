import { Switch, Route, withRouter } from 'react-router-dom'
import { NotFound } from 'components/core-ui'
import { Home } from '../Home'
import { Case } from '../Case'
import { Reports } from '../Reports'
import { Layout } from '../Layout'
import { Login } from '../Login'
import { Admin } from '../Admin'
import { UserProfile } from '../UserProfile'
import { URLS } from 'utils/constants'
import { PrivateRoute } from 'components/utils/PrivateRoute'

const App = () => (
  <Layout>
    <div style={{ height: '100%', padding: '7rem 0 0 0logoWrapper' }}>
      <Switch>
        <Route exact path={URLS.LOGIN} component={Login} />
        <PrivateRoute exact path={URLS.AUTH_HOME} component={Home} />
        <PrivateRoute exact path={URLS.AUTH_CASE} component={Case} />
        <PrivateRoute exact path={URLS.AUTH_REPORTS} component={Reports} />
        <PrivateRoute exact path={URLS.AUTH_ADMIN} component={Admin} allow="admin" />
        <PrivateRoute exact path={URLS.AUTH_PROFILE} component={UserProfile} />
        <Route component={NotFound} />
      </Switch>
    </div>
  </Layout>
)

export default withRouter(App)
