import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { TIME_SPANS } from 'utils/constants'
import s from './TimeSpanSelector.scss'

const TimeSpanSelector = ({ selected, onFilterChange }) => {
  return (
    <ToggleButtonGroup
      value={selected}
      exclusive
      onChange={(event, value) => onFilterChange('timeSpan', value)}
      className={s.container}
    >
      {TIME_SPANS.map(s => (
        <ToggleButton key={s.value} value={s.value} aria-label="left aligned">
          {s.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

export default TimeSpanSelector
