import { useEffect, useState, useRef } from 'react'

const useLoading = () => {
  const [isLoading, setState] = useState(false)
  const mount = useRef(false)

  useEffect(() => {
    mount.current = true
    return () => (mount.current = false)
  }, [])

  useEffect(() => {
    if (document.getElementById('app')) {
      if (isLoading) {
        document.body.style.cursor = 'wait'
        document.getElementById('app').style.pointerEvents = 'none'
      } else {
        document.body.style.cursor = 'default'
        document.getElementById('app').style.pointerEvents = 'auto'
      }
    }
  }, [isLoading])

  const load = aPromise => {
    setState(true)
    return aPromise.finally(() => {
      if (mount.current) setState(false)
    })
  }
  return [isLoading, load]
}

export default useLoading
