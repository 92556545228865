import { useState, useEffect } from 'react'
import moment from 'moment'
import cn from 'classnames'
import { ReportsPieChart } from './ReportsPieChart'
import { CaseBarChart } from '../CaseBarChart'
import { makeGetRequest } from 'utils/api'
import { toPieData } from './serializers'
import { toFilterParams } from '../serializers'
import { getReportsBarChartTitle, getReportsBarChartInterval } from '../helpers'
import s from './ReportsCharts.scss'

const today = moment().endOf('day')
const yesterday = moment().subtract(1, 'days')

const ReportsCharts = ({ filters }) => {
  const [data, setData] = useState([])
  const [counts, setCounts] = useState({})
  const [pieData, setPieData] = useState([])

  useEffect(() => {
    ;(async () => {
      const response = await makeGetRequest('/api/classification_ticket/bar-chart-data', {
        params: { ...toFilterParams(filters), sortColumn: 'name', sortOrder: 'ASC' }
      })
      setData(response.content)
      setCounts(response.totals)
    })()
  }, [filters])

  useEffect(() => {
    ;(async () => {
      const response = await makeGetRequest('/api/classification_ticket/bar-chart-data', {
        params: {
          startDate: yesterday.toDate(),
          endDate: today.toDate(),
          sortColumn: 'name',
          sortOrder: 'ASC'
        }
      })
      setPieData(response.totals)
    })()
  }, [])

  return (
    <section className={s.graphSection}>
      <div className={cn(s.leftBox, s.box)}>
        <h5>
          <strong>Astazi</strong>
          <span>{today.format('DD.MM.YYYY')}</span>
        </h5>
        <div className={s.graphContent}>
          <ReportsPieChart data={toPieData(pieData)} />
        </div>
        <ul className={s.graphLegend}>
          <li className={s.all}>
            <strong>{pieData.totalcases}</strong>
            <span>Cazuri</span>
          </li>
          <li className={s.processed}>
            <strong>{pieData.nluProcessed}</strong>
            <span>Procesate in NLU</span>
          </li>
          <li className={s.pending}>
            <strong>{pieData.createdCrmTicket}</strong>
            <span>Create in CRM</span>
          </li>
          <li className={s.unprocessed}>
            <strong>{pieData.unprocessed}</strong>
            <span>Neprocesate</span>
          </li>
        </ul>
      </div>
      <div className={s.box}>
        <h5>
          <strong>{getReportsBarChartTitle(filters.timeSpan)}</strong>
          <span>{getReportsBarChartInterval(filters.timeSpan)}</span>
        </h5>
        <CaseBarChart data={data} counts={counts} />
      </div>
    </section>
  )
}

export default ReportsCharts
