import { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import cn from 'classnames'
import { makePostRequest } from 'utils/api'
import s from '../Login.scss'

const ForgotPassword = ({ props, className, setIsLoginFormVisible }) => {
  const [isFormVisible, setIsFormVisible] = useState(true)

  const formSubmit = async (values, resetForm) => {
    const { username } = values

    const response = await makePostRequest('/api/auth/reset-password', {
      username
    })

    if (!response) {
      //TODO push notification
    }

    resetForm()
    setIsFormVisible(false)
  }

  return (
    <>
      <div className={cn(s.backLink, className)} onClick={() => setIsLoginFormVisible(true)}>
        <span className={s.hideForgotPassBtn}>Inapoi la autentificare</span>
      </div>
      <div className={cn(s.forgotPassBox, className)}>
        <Formik
          props={props}
          initialValues={{ username: '' }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            formSubmit(values, resetForm).then(() => setTimeout(() => setSubmitting(false), 2000))
          }}
          validationSchema={Yup.object().shape({
            username: Yup.string().required('Numele de utilizator este obligatoriu')
          })}
        >
          {props => {
            const { values, touched, errors, handleBlur, handleChange, handleSubmit } = props

            return (
              <div className={s.forgotPassContent}>
                <div className={cn(s.forgotPassContentWrapper, { [s.hideForm]: !isFormVisible })}>
                  <div className={cn(s.forgotPassContentTab, s.visible)}>
                    <div className={s.table}>
                      <div className={s.tableCell}>
                        <form className={s.form} onSubmit={handleSubmit}>
                          <h3>Ai uitat parola?</h3>
                          <p>
                            Scrie numele de utilizator pe care il folosesti pentru autentificare.
                            Iti vom trimite noua parola pe adresa de email asociata cu contul tau.
                          </p>
                          <div className={s.formRow}>
                            <input
                              id="username"
                              type="text"
                              name="username"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.username}
                              placeholder="Nume utilizator"
                            />
                            {errors.username && touched.username && (
                              <div className={s.inputFeedback}>{errors.username}</div>
                            )}
                          </div>
                          <div className={s.formRow}>
                            <button className={s.button} type="submit">
                              Trimite
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className={cn(s.forgotPassContentTab, s.successMsg)}>
                    <div className={s.table}>
                      <div className={s.tableCell}>
                        <div className={s.forgotPassMessage}>
                          <h3>Verifica inbox</h3>
                          <p>
                            Un mesaj a fost trimis pe adresa de email asociata contului tau cu noua{' '}
                            parola.
                          </p>
                          <span
                            className={s.hideForgotPass}
                            onClick={() => {
                              setIsFormVisible(true)
                              setIsLoginFormVisible(true)
                            }}
                          >
                            Inapoi la autentificare
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default ForgotPassword
