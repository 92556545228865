import { ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts'
import s from './CaseBarChart.scss'

const CaseBarChart = ({ data, counts }) => {
  return (
    <>
      <div className={s.chartContainer}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="nluProcessed" fill="#01B747" barSize={20} />
            <Bar dataKey="createdCrmTicket" fill="#4285f4" barSize={20} />
            <Bar dataKey="unprocessed" fill="#FF2E00" barSize={20} />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <ul className={s.graphLegend}>
        <li className={s.all}>
          <strong>{counts.totalcases}</strong>
          <span>Cazuri</span>
        </li>
        <li className={s.processed}>
          <strong>{counts.nluProcessed}</strong>
          <span>Procesate in NLU</span>
        </li>
        <li className={s.pending}>
          <strong>{counts.createdCrmTicket}</strong>
          <span>Create in CRM</span>
        </li>
        <li className={s.unprocessed}>
          <strong>{counts.unprocessed}</strong>
          <span>Neprocesate</span>
        </li>
      </ul>
    </>
  )
}

export default CaseBarChart
