import moment from 'moment-timezone'
import * as ACT from 'actions/actionTypes'

export const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1)

export const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item]
    }),
    {}
  )

export const preventDefault = event => {
  event.stopPropagation()
  event.preventDefault()
}

export const whenIsDate = date => {
  // returns 0 if today, negative if in the future else positive
  const today = moment(new Date())
  const dueDate = moment(date)
  return !dueDate.isSame(today, 'day') && today.diff(dueDate.format('MM/DD/YYYY'))
}

export const refreshNotifications = put => {
  return put({
    type: ACT.NOTIFICATIONS_FETCH_REQUESTED
  })
}

export const hasJsonStructure = str => {
  if (typeof str !== 'string') return false
  try {
    const result = JSON.parse(str)
    const type = Object.prototype.toString.call(result)
    return type === '[object Object]' || type === '[object Array]'
  } catch (err) {
    return false
  }
}

export const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

export const numberRange = (start, end, oddOrEven) => {
  if (!oddOrEven) return new Array(end + 1 - start).fill().map((d, i) => i + start)
  if (oddOrEven === 'even') {
    return new Array(end + 1 - start)
      .fill()
      .map((d, i) => i + start)
      .filter(d => d % 2 === 0)
  } else {
    return new Array(end + 1 - start)
      .fill()
      .map((d, i) => i + start)
      .filter(d => d % 2 !== 0)
  }
}

export const stripHtml = html => {
  const tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

export const removeSubstringAfterCharacter = (string, char) =>
  string.substr(0, string.lastIndexOf(char))

export const sortAlphabetically = (arr, key) => {
  return arr.sort(function(a, b) {
    if (a[key].toLowerCase() < b[key].toLowerCase()) return -1
    if (a[key].toLowerCase() > b[key].toLowerCase()) return 1
    return 0
  })
}

export const getStatusLabel = (status, notProcessed = false) => {
  if (notProcessed) return 'Neprocesat'
  switch (status) {
    case 'Unprocessed':
      return 'Neprocesat'
    case 'NLU Processed':
      return 'Procesat in NLU'
    case 'Error':
      return 'Erori'
    default:
      return 'Creat in CRM'
  }
}

export const getStatusClassName = (status, notProcessed = false) => {
  if (notProcessed) return 'redStatus'

  switch (status) {
    case 'Unprocessed':
      return 'redStatus'
    case 'Error':
      return 'redStatus'
    case 'NLU Processed ':
      return 'blueStatus'
    default:
      return 'greenStatus'
  }
}

export const isValidTag = htmlTag => {
  try {
    return document.createElement(htmlTag).toString() != '[object HTMLUnknownElement]'
  } catch (error) {
    return false
  }
}

export const sanitaizeHtmlCode = text => {
  const found = text.match(/(<([^>]+)>)/gi)
  if (found === null) return ''
  found.forEach(element => {
    const tag = element
      .replaceAll('<', '')
      .replaceAll('/', '')
      .replaceAll('>', '')

    if (!isValidTag(tag)) {
      text = text.replace(element, element.replaceAll('<', '&lt;').replaceAll('>', '&gt;'))
    }
  })

  return text
}

// eslint-disable-next-line no-control-regex
export const handleLineBreak = text => text.replace(new RegExp('\r?\n', 'g'), '<br/>')
