import { useState, useEffect } from 'react'
import { FiUser, FiLock } from 'react-icons/fi'
import cn from 'classnames'
import moment from 'moment'
import { getUser } from 'utils/authService'
import { makeGetRequest } from 'utils/api'
import { ChangePassword } from './ChangePassword'
import s from './UserProfile.scss'

const userInitialState = {
  firstName: '',
  lastName: '',
  username: '',
  password: '',
  email: '',
  role: {},
  status: {}
}

const UserProfile = () => {
  const [formVisible, setFormVisible] = useState(false)
  const [user, setUser] = useState(userInitialState)

  const loggedUser = getUser()

  useEffect(() => {
    ;(async () => {
      const result = await makeGetRequest(`/api/user/${loggedUser.id}`)
      setUser(result)
    })()
  }, [loggedUser.id])

  return (
    <div className={s.profileContainer}>
      <div className={s.profileBox}>
        <div className={s.profileDetails}>
          <div className={s.profileImage}>
            <FiUser />
          </div>
          <div>
            <h3>
              {user.firstName} {user.lastName}
            </h3>
            <p>
              <a href="mailto:name@mail.com">{user.email}</a>
            </p>
          </div>
        </div>
        <div className={s.profileAction}>
          <span className={s.button} onClick={() => setFormVisible(true)}>
            <FiLock />
            Schimba parola
          </span>
        </div>
      </div>
      <div className={s.accountDetails}>
        <ul>
          <li>
            <span>Nume utilizator</span>
            <p>{user.userName}</p>
          </li>
          <li>
            <span>Rol</span>
            <p>{user.role.name}</p>
          </li>
          <li>
            <span>Data ultimului login</span>
            <p>
              {user.lastLoginDate ? moment(user.lastLoginDate).format('DD.MM.YYYY, HH:mm') : '-'}
            </p>
          </li>
          <li>
            <span>Status</span>
            <p>{user.status.name}</p>
          </li>
        </ul>
      </div>
      <div className={cn(s.changePassword, { [s.visible]: formVisible })}>
        <ChangePassword setFormVisible={setFormVisible} />
      </div>
    </div>
  )
}

export default UserProfile
