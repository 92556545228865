import { Component } from 'react'
import moment from 'moment'

import DayPickerInput from 'react-day-picker/DayPickerInput'
import s from './RangeDatePicker.scss'

import { formatDate, parseDate } from 'react-day-picker/moment'
// import { timezoneDate } from 'utils/helpers'
import { getDateFormat } from 'utils/authService'
import { FaArrowsAltH } from 'react-icons/fa'

class RangeDatePicker extends Component {
  state = {
    from: moment()
      .subtract(2, 'weeks')
      .startOf('day')
      .toDate(),
    to: moment()
      .add(6, 'weeks')
      .endOf('day')
      .toDate()
  }

  componentDidUpdate(prevProps, prevState) {
    const start = this.props.startDate //timezoneDate(this.props.startDate, null, null, true).toDate()
    const end = this.props.endDate //timezoneDate(this.props.endDate, null, null, true).toDate()

    if (
      moment(start)
        .format()
        .split('T')[0] !==
        moment(prevState.from)
          .format()
          .split('T')[0] ||
      moment(end)
        .format()
        .split('T')[0] !==
        moment(prevState.to)
          .format()
          .split('T')[0]
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        from: start,
        to: end
      })
    }
  }

  showFromMonth = () => {
    const { from, to } = this.state
    if (!from) {
      return
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from)
    }
  }

  setDates = () => {
    const dates = this.state

    this.props.onChange &&
      this.props.onChange({
        from: moment(dates.from)
          .startOf('day')
          .toDate(),
        to: moment(dates.to)
          .endOf('day')
          .toDate()
      })
  }

  handleFromChange = from => {
    // Change the from date and focus the "to" input field
    this.setState(
      {
        from: moment(from).toDate()
      },
      () => {
        this.setDates()
      }
    )
  }

  handleToChange = to => {
    this.setState(
      {
        to: moment(to).toDate()
      },
      () => {
        this.showFromMonth()
        this.setDates()
      }
    )
  }

  render() {
    const { from, to } = this.state
    const modifiers = { start: from, end: to }
    return (
      <div className={s.InputFromTo}>
        <DayPickerInput
          value={from}
          format={getDateFormat()}
          formatDate={formatDate}
          parseDate={parseDate}
          inputProps={{ readOnly: true }}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { after: to },
            toMonth: to,
            modifiers,
            onDayClick: () => this.to.getInput().focus()
          }}
          onDayChange={this.handleFromChange}
          keepFocus={false}
          placeholder="DD.MM.YYYY"
        />{' '}
        <FaArrowsAltH className={s.dateSeparatorIcon} />{' '}
        <span className={s.InputFromToTo}>
          <DayPickerInput
            ref={el => (this.to = el)}
            value={to}
            format={getDateFormat()}
            formatDate={formatDate}
            parseDate={parseDate}
            inputProps={{ readOnly: true }}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { before: from },
              modifiers,
              month: from,
              fromMonth: from
            }}
            onDayChange={this.handleToChange}
            placeholder="DD.MM.YYYY"
          />
        </span>
      </div>
    )
  }
}

export default RangeDatePicker
