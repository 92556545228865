import { combineReducers } from 'redux'
// import homeReducer from 'components/Home/reducer'
import { connectRouter } from 'connected-react-router'

const createRootReducer = history =>
  combineReducers({
    // home: homeReducer,
    router: connectRouter(history)
  })

export default createRootReducer
