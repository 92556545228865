import cn from 'classnames'
import { FiArrowRight } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { getStatusClassName, getStatusLabel } from 'utils/helpers'
import s from './CaseDetailsRight.scss'

const CaseDetailsRight = ({ caseData }) => {
  return (
    <div className={s.caseDetails}>
      <div className={s.caseDetailsHeader}>
        <span className={cn(s.statusLabel, s[getStatusClassName(caseData.status.name)])}>
          {getStatusLabel(caseData.status.name)}
        </span>
        <Link to="/" onClick={event => event.preventDefault()} className={s.sfBtn} href="#">
          Deschide in salesforce
          <FiArrowRight className={s.arrow} />
        </Link>
      </div>
      <ul className={s.deatilsContainer}>
        <li>
          <span>Numar caz</span>
          <p>{caseData.id}</p>
        </li>
        {caseData.distributionCode?.code && (
          <li>
            <span>POD / NLC</span>
            <p>{caseData.distributionCode.code}</p>
          </li>
        )}
        <li>
          <span>Agent alocat</span>
          <p>{caseData.agent.name}</p>
        </li>
        {caseData.userIdentifier?.code && (
          <li>
            <span>CUI / CNP</span>
            <p>{caseData.userIdentifier.code}</p>
          </li>
        )}
        {caseData.sf_id && (
          <li>
            <span>ID SALESFORCE</span>
            <p>{caseData.sf_id}</p>
          </li>
        )}
        {caseData.category?.name && (
          <li>
            <span>Categorie</span>
            <p>{caseData.category.name}</p>
          </li>
        )}
      </ul>
    </div>
  )
}

export default CaseDetailsRight
