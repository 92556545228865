import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Select from 'react-select'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { makePostRequest, makePatchRequest, makeGetRequest } from 'utils/api'
import { toRolesSelect, toUserPostData, toUserData } from './serializers'
import s from './UserModal.scss'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
}

const userInitialState = {
  firstName: '',
  lastName: '',
  username: '',
  password: '',
  email: '',
  role: ''
}

const createValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Prenumele este obligatoriu.'),
  lastName: Yup.string().required('Numele este obligatoriu.'),
  username: Yup.string().required('Numele de utilizator este obligatoriu.'),
  password: Yup.string().required('Parola este obligatorie.'),
  email: Yup.string()
    .email('Adresa de email este invalida.')
    .required('Adresa de email este obligatorie.'),
  role: Yup.object().shape({
    label: Yup.string().required('Trebuie selectat un rol.'),
    value: Yup.string().required('Trebuie selectat un rol.')
  })
})

const editValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Prenumele este obligatoriu.'),
  lastName: Yup.string().required('Numele este obligatoriu.'),
  email: Yup.string()
    .email('Adresa de email este invalida.')
    .required('Adresa de email este obligatorie.'),
  role: Yup.object().shape({
    label: Yup.string().required('Trebuie selectat un rol.'),
    value: Yup.string().required('Trebuie selectat un rol.')
  })
})

const UserModal = ({ props, open, setOpen, currentUserId, setCurrentUserId, getUsers }) => {
  const [roles, setRoles] = useState([])
  const [currentUser, setCurrentUser] = useState(userInitialState)

  useEffect(() => {
    ;(async () => {
      const result = await makeGetRequest('/api/user/role')
      setRoles(toRolesSelect(result.rows))
    })()
  }, [])

  useEffect(() => {
    if (currentUserId) {
      ;(async () => {
        const result = await makeGetRequest(`/api/user/${currentUserId}`)
        setCurrentUser(toUserData(result))
      })()
    } else {
      setCurrentUser(userInitialState)
    }
  }, [currentUserId])

  const formSubmit = async values => {
    //TODO role validation is not working!
    let response
    if (currentUserId) {
      response = await makePatchRequest(`/api/user/${currentUserId}`, toUserPostData(values))
      setCurrentUserId(null)
    } else {
      response = await makePostRequest('/api/user', toUserPostData(values))
    }

    if (!response) {
      //TODO push notification
    }

    //TODO find a better way to refresh table
    await getUsers()

    setOpen(false)
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={s.userModalContent}>
            <h2>{currentUserId ? 'Editeaza' : 'Adauga'} utilizator</h2>
            <Formik
              enableReinitialize={true}
              props={props}
              initialValues={currentUser}
              onSubmit={(values, { setSubmitting }) => {
                formSubmit(values).then(() => setTimeout(() => setSubmitting(false), 2000))
              }}
              validationSchema={currentUserId ? editValidationSchema : createValidationSchema}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue
                } = props

                return (
                  <div>
                    <form className={s.form} onSubmit={handleSubmit}>
                      <fieldset>
                        <div className={s.formRow}>
                          <label htmlFor="userFirstName">Prenume</label>
                          <input
                            id="userFirstName"
                            type="text"
                            name="firstName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstName}
                            placeholder="Prenume"
                          />
                          {errors.firstName && touched.firstName && (
                            <div className={s.inputFeedback}>{errors.firstName}</div>
                          )}
                        </div>
                        <div className={s.formRow}>
                          <label htmlFor="userLastName">Nume</label>
                          <input
                            id="userLastName"
                            type="text"
                            name="lastName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastName}
                            placeholder="Nume"
                          />
                          {errors.lastName && touched.lastName && (
                            <div className={s.inputFeedback}>{errors.lastName}</div>
                          )}
                        </div>
                        <div className={s.formRow}>
                          <label htmlFor="username">Nume utilizator</label>
                          <input
                            id="username"
                            type="text"
                            name="username"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.username}
                            placeholder="Nume utilizator"
                            disabled={currentUserId === currentUser.id}
                          />
                          {errors.username && touched.username && (
                            <div className={s.inputFeedback}>{errors.username}</div>
                          )}
                        </div>
                        <div className={s.formRow}>
                          <label htmlFor="password">Parola</label>
                          <input
                            id="password"
                            type="text"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            placeholder="Parola"
                            disabled={currentUserId === currentUser.id}
                          />
                          {errors.password && touched.password && (
                            <div className={s.inputFeedback}>{errors.password}</div>
                          )}
                        </div>
                        <div className={s.formRow}>
                          <label htmlFor="userEmail">Email</label>
                          <input
                            id="userEmail"
                            type="text"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            placeholder="Email"
                          />
                          {errors.email && touched.email && (
                            <div className={s.inputFeedback}>{errors.email}</div>
                          )}
                        </div>
                        <div className={s.formRow}>
                          <label htmlFor="role">Rol utilizator</label>
                          <Select
                            value={values.role ? values.role : ''}
                            placeholder="Rol utilizator"
                            onChange={value => setFieldValue('role', value)}
                            options={roles}
                          />
                          {errors.role && touched.role && (
                            <div className={s.inputFeedback}>{errors.role.label}</div>
                          )}
                        </div>
                      </fieldset>
                      <div>
                        <button className={s.submitBtn} type="submit">
                          {currentUserId ? 'Editeaza' : 'Adauga'} utilizator
                        </button>
                      </div>
                    </form>
                  </div>
                )
              }}
            </Formik>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default UserModal
