import b64toBlob from 'b64-to-blob'

export const exportData = data => {
  var test = window.URL || window.webkitURL
  let contentType = data.contentType.split(';')
  const bolb = b64toBlob(data.data, contentType)
  const url = test.createObjectURL(bolb)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', data.fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
