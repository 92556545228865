import { useState, useEffect } from 'react'
import { FiArrowLeft } from 'react-icons/fi'
import { CaseBarChart } from '../CaseBarChart'
import { makeGetRequest } from 'utils/api'
import { toFilterParams } from '../../ReportsContent/serializers'
import s from './BigCharts.scss'

const BigCharts = ({ setShowBigCharts, filters }) => {
  const [data, setData] = useState([])
  const [counts, setCounts] = useState({})

  useEffect(() => {
    ;(async () => {
      const response = await makeGetRequest('/api/classification_ticket/bar-chart-data', {
        params: { ...toFilterParams(filters), sortColumn: 'name', sortOrder: 'ASC' }
      })
      setData(response.content)
      setCounts(response.totals)
    })()
  }, [filters])

  return (
    <>
      <div className={s.dsSlideHeader}>
        <span className={s.closeStatsOverlay} onClick={() => setShowBigCharts(false)}>
          <FiArrowLeft />
          Vezi cazuri
        </span>
      </div>
      <div className={s.box}>
        <CaseBarChart data={data} counts={counts} />
      </div>
    </>
  )
}

export default BigCharts
