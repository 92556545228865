import { useState, useEffect, useCallback } from 'react'
import { useImmer } from 'use-immer'
import { Filters } from './Filters'
import { Table } from './Table'
import { toUsersData } from './serializers'
import { makeGetRequest } from 'utils/api'
import s from './Admin.scss'

const Admin = () => {
  const [openUserModal, setOpenUserModal] = useState(false)
  const [currentUserId, setCurrentUserId] = useState(null)
  const [data, setData] = useState([])

  const [filters, setFilters] = useImmer({ searchQuery: '' })

  const getUsers = useCallback(async () => {
    const result = await makeGetRequest('/api/user', {
      params: { searchQuery: filters.searchQuery }
    })
    setData(toUsersData(result.rows))
  }, [filters])

  useEffect(() => {
    getUsers()
  }, [getUsers])

  const searchItems = searchValue => {
    setFilters(state => {
      state.searchQuery = searchValue
    })
  }

  return (
    <>
      <Filters
        setOpenUserModal={setOpenUserModal}
        setCurrentUserId={setCurrentUserId}
        getUsers={getUsers}
        searchItems={searchItems}
      />
      <div className={s.contentBoxBody}>
        <Table
          data={data}
          setData={setData}
          openUserModal={openUserModal}
          setOpenUserModal={setOpenUserModal}
          currentUserId={currentUserId}
          setCurrentUserId={setCurrentUserId}
          getUsers={getUsers}
        />
      </div>
    </>
  )
}

export default Admin
