import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'
import s from './FiltersToggler.scss'

const FiltersToggler = ({ filtersVisible, toggleFilters }) => {
  return (
    <span className={s.triggerFilters} onClick={toggleFilters}>
      Filtreaza
      {filtersVisible ? <AiFillCaretUp /> : <AiFillCaretDown />}
    </span>
  )
}

export default FiltersToggler
