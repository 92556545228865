import { useEffect } from 'react'
import { useImmer } from 'use-immer'
import { FiArrowRight } from 'react-icons/fi'
import { SmallChart } from './SmallChart'
import { makeGetRequest } from 'utils/api'
import { toFilterParams } from '../serializers'
import s from './SmallCharts.scss'

const initialState = {
  totalCases: {
    title: 'Total cazuri',
    color: '#55BD5A',
    total: 0,
    percentage: 0,
    trend: '',
    content: []
  },
  nluProcessed: {
    title: 'Procesate in NLU',
    color: '#55BD5A',
    total: 0,
    percentage: 0,
    trend: '',
    content: []
  },
  createdCrmTicket: {
    title: 'Create in CRM',
    color: '#53A7FF',
    total: 0,
    percentage: 0,
    trend: '',
    content: []
  },
  unprocessed: {
    title: 'Neprocesate',
    color: '#FF1065',
    total: 0,
    percentage: 0,
    trend: '',
    content: []
  }
}

const SmallCharts = ({ setShowBigCharts, filters }) => {
  const [chartsData, setChartsData] = useImmer(initialState)

  useEffect(() => {
    ;(async () => {
      const result = await makeGetRequest('/api/classification_ticket/line-chart-data', {
        params: { ...toFilterParams(filters), sortColumn: 'name', sortOrder: 'ASC' }
      })
      setChartsData(state => {
        Object.keys(state).map(key => {
          state[key].total = result[key].total
          state[key].content = result[key].content.map(c => ({ ...c, cases: +c.cases }))
          state[key].percentage = result[key].precentFromLastTime
          state[key].trend =
            result[key].precentFromLastTime > 0
              ? 'up'
              : result[key].precentFromLastTime < 0
              ? 'down'
              : ''
        })
      })
    })()
  }, [filters, setChartsData])

  return (
    <div className={s.chartsContainer}>
      {Object.keys(chartsData).map(key => {
        const data = chartsData[key]
        return (
          <SmallChart
            color={data.color}
            chartTitle={data.title}
            key={data.title}
            total={data.total}
            percentage={data.percentage}
            trend={data.trend}
            data={data.content}
          />
        )
      })}
      <div className={s.triggerStatsOverlay} onClick={() => setShowBigCharts(true)}>
        <FiArrowRight />
      </div>
    </div>
  )
}

export default SmallCharts
