import { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FiCheck } from 'react-icons/fi'
import cn from 'classnames'
import { makePatchRequest } from 'utils/api'
import s from './ChangePassword.scss'

const initialValues = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: ''
}

const ChangePassword = ({ props, setFormVisible }) => {
  const [error, setError] = useState('')

  const formSubmit = async (values, resetForm) => {
    const { currentPassword, newPassword, confirmNewPassword } = values

    try {
      const response = await makePatchRequest('/api/user/change-password', {
        old_password: currentPassword,
        new_password: newPassword,
        confirmed_password: confirmNewPassword
      })

      if (!response) {
        //TODO push notification
      }

      setFormVisible(false)
      resetForm(initialValues)
      setError('')
    } catch (error) {
      setError('Parola curenta nu este corecta.')
    }
  }

  const formReset = () => {
    setFormVisible(false)
    setError('')
  }

  return (
    <Formik
      enableReinitialize={true}
      props={props}
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        formSubmit(values, resetForm).then(() =>
          setTimeout(() => {
            setSubmitting(false)
          }, 2000)
        )
      }}
      onReset={formReset}
      validationSchema={Yup.object().shape({
        currentPassword: Yup.string().required('Parola curenta este obligatorie'),
        newPassword: Yup.string().required('Parola noua este obligatorie'),
        confirmNewPassword: Yup.string()
          .oneOf([Yup.ref('newPassword'), null], 'Parolele trebuie sa se potriveasca')
          .required('Parola noua este obligatorie')
      })}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          handleReset
        } = props

        return (
          <>
            <div className={s.titleDesc}>
              <h3>Modifica parola</h3>
              <p>
                Cand schimbati parola va rugam sa setati o parola sigura care sa contina litere{' '}
                mari, litere mici si numere. Este pentru propria siguranta.
              </p>
            </div>
            <form className={s.form} onSubmit={handleSubmit}>
              <div className={s.fieldsetRow}>
                <div className={s.formRow}>
                  <label>Parola curenta</label>
                  <input
                    type="text"
                    name="currentPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.currentPassword}
                    placeholder="Parola curenta"
                  />
                  {errors.currentPassword && touched.currentPassword && (
                    <div className={s.error}>{errors.currentPassword}</div>
                  )}
                </div>
                <div className={s.formRow}>
                  <label>Parola noua</label>
                  <input
                    type="text"
                    name="newPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPassword}
                    placeholder="Parola noua"
                  />
                  {errors.newPassword && touched.newPassword && (
                    <div className={s.error}>{errors.newPassword}</div>
                  )}
                </div>
                <div className={s.formRow}>
                  <label>Confirma parola noua</label>
                  <input
                    type="text"
                    name="confirmNewPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmNewPassword}
                    placeholder="Confirma parola noua"
                  />
                  {errors.confirmNewPassword && touched.confirmNewPassword && (
                    <div className={s.error}>{errors.confirmNewPassword}</div>
                  )}
                </div>
              </div>
              {error && <p className={s.error}>{error}</p>}
              <div className={s.formActions}>
                <button className={s.button} type="submit">
                  Save
                  <FiCheck />
                </button>
                <button className={cn(s.button, s.cancelButton)} onClick={handleReset}>
                  Cancel
                </button>
              </div>
            </form>
          </>
        )
      }}
    </Formik>
  )
}

export default ChangePassword
