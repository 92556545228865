import moment from 'moment'

const now = moment()
const oneDay = moment().subtract(1, 'days')
const oneWeek = moment().subtract(1, 'weeks')
const oneMonth = moment().subtract(1, 'months')
const threeMonths = moment().subtract(3, 'months')
const oneYear = moment().subtract(1, 'years')

export const getDatesFromTimespan = timeSpan => {
  switch (timeSpan) {
    case '24h':
      return [oneDay.toDate(), now.toDate()]
    case '7d':
      return [oneWeek.toDate(), now.toDate()]
    case '1m':
      return [oneMonth.toDate(), now.toDate()]
    case '3m':
      return [threeMonths.toDate(), now.toDate()]
    case '1y':
      return [oneYear.toDate(), now.toDate()]
    default:
      return []
  }
}

export const getReportsBarChartTitle = timeSpan => {
  switch (timeSpan) {
    case '24h':
      return 'Ultima zi'
    case '7d':
      return 'Ultima saptamana'
    case '1m':
      return 'Ultima luna'
    case '3m':
      return 'Ultimele 3 luni'
    case '1y':
      return 'Ultimul an'
    default:
      return 'All time'
  }
}

export const getReportsBarChartInterval = timeSpan => {
  switch (timeSpan) {
    case '24h':
      return `${oneDay.format('DD.MM.YYYY')} - ${now.format('DD.MM.YYYY')}`
    case '7d':
      return `${oneWeek.format('DD.MM.YYYY')} - ${now.format('DD.MM.YYYY')}`
    case '1m':
      return `${oneMonth.format('DD.MM.YYYY')} - ${now.format('DD.MM.YYYY')}`
    case '3m':
      return `${threeMonths.format('DD.MM.YYYY')} - ${now.format('DD.MM.YYYY')}`
    case '1y':
      return `${oneYear.format('DD.MM.YYYY')} - ${now.format('DD.MM.YYYY')}`
    default:
      return 'All time'
  }
}
