export const toRolesSelect = roles =>
  roles.map(role => ({
    value: role.id,
    label: role.name
  }))

export const toUserPostData = values => ({
  firstName: values.firstName,
  lastName: values.lastName,
  username: values.username,
  password: values.password,
  email: values.email,
  role_id: values.role.value
})

export const toUserData = data => ({
  ...data,
  username: data.userName,
  role: { value: data.role.id, label: data.role.name }
})
