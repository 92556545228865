import { Header } from './Header'
import { isAuthenticated } from 'utils/authService'
import s from './Layout.scss'

const Layout = ({ history, children }) => {
  const isAuth = isAuthenticated()

  return isAuth ? (
    <>
      <Header history={history} />
      <div className={s.content}>{children}</div>
    </>
  ) : (
    <div className={s.loginPage}>
      <div>{children}</div>
    </div>
  )
}

export default Layout
