import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'

import { createBrowserHistory } from 'history'

import createRootReducer from './rootReducer'
const history = createBrowserHistory({
  basename: '/agent'
})
export { history }

export const sagaMiddleware = createSagaMiddleware()

const loadingLockMiddleware = () => next => action => {
  if (action.loadingLock === 'on') {
    document.body.style.cursor = 'wait'
    document.getElementById('app').style.pointerEvents = 'none'
  } else if (action.loadingLock === 'off') {
    document.body.style.cursor = 'default'
    document.getElementById('app').style.pointerEvents = 'auto'
  }

  next(action)
}

const middleware = [loadingLockMiddleware, routerMiddleware(history), sagaMiddleware]

if (process.env.ENV !== 'production') {
  const logger = require('redux-logger').default
  middleware.push(logger)
}

export default function configureStore(initialState = {}) {
  const store = createStore(
    createRootReducer(history),
    initialState,
    compose(applyMiddleware(...middleware))
  )

  return store
}
