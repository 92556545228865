import { FiUserPlus } from 'react-icons/fi'
import { Search } from 'components/common/Search'
import s from './Filters.scss'

const Filters = ({ setOpenUserModal, setCurrentUserId, searchItems }) => {
  return (
    <div className={s.contentBoxHeader}>
      <div />
      <div>
        <Search searchItems={searchItems} />
        <span
          className={s.triggerOverlayAddUser}
          onClick={() => {
            setOpenUserModal(true)
            setCurrentUserId(null)
          }}
        >
          <FiUserPlus />
          Adauga utilizator
        </span>
      </div>
    </div>
  )
}

export default Filters
