import { useState, useEffect } from 'react'
import { VscSearch } from 'react-icons/vsc'
import s from './Search.scss'

const Search = ({ searchItems }) => {
  const [value, setValue] = useState('')
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      ;(value.length > 2 || value.length == 0) && searchItems(value)
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [value, searchItems])

  const onChange = e => {
    const newValue = e.target.value
    setValue(newValue)
  }

  return (
    <div className={s.contentBoxHeaderSearch}>
      <fieldset>
        <VscSearch />
        <input type="text" name="" placeholder="Cauta" value={value} onChange={onChange} />
      </fieldset>
    </div>
  )
}

export default Search
