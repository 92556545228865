import Select from 'react-select'
import cn from 'classnames'
import { toCategoriesSelectData, toConstArraySelectData, toStatusesSelectData } from './serializers'
import { USER_IDENTIFIERS, DISTRIBUTION_CODE } from './constants'
import { FaTimes } from 'react-icons/fa'
import { FiRefreshCcw } from 'react-icons/fi'
import s from './FiltersContainer.scss'
import moment from 'moment'

const customStyles = {
  control: provided => ({
    ...provided,
    height: '5rem',
    border: '1px solid #CCDFE5',
    color: '#062731 !important',
    borderRadius: '0 !important',
    fontFamily: "'Ubuntu', Arial, sans-serif",
    fontWeight: '400 !important'
  })
}

const renderSelectedFilter = filter => {
  if (filter instanceof Date) {
    return <span className={s.filterName}>{moment(filter).format('DD.MM.YYYY')}</span>
  } else if (filter instanceof Object) {
    return <span className={s.filterName}>{filter.label}</span>
  } else {
    return <span className={s.filterName}>{filter}</span>
  }
}

const availableFiltersForDisplay = [
  'category',
  'agent',
  'userIdentifierType',
  'status',
  'distributionCodeType',
  'sf_id',
  'searchQuery'
]

const shouldDisplaySelectedFilter = filterKey => {
  return availableFiltersForDisplay.includes(filterKey)
}

const FiltersContainer = ({ categories, statuses, isVisible, selectedFilters, onFilterChange }) => {
  return (
    <div className={cn({ [s.visible]: isVisible }, s.contentBoxSubheader)}>
      <div className={cn({ [s.visible]: isVisible }, s.filtersContent)}>
        <h4>Filtreaza</h4>
        <div className={s.form}>
          <Select
            onChange={value => {
              onFilterChange('category', value)
            }}
            options={toCategoriesSelectData(categories)}
            className={s.dropDown}
            placeholder="Categorii"
            styles={customStyles}
            value={selectedFilters.category}
          />
          <div className={s.formRow}>
            <input
              type="text"
              placeholder="Agent"
              onBlur={e => onFilterChange('agent', e.target.value)}
            />
          </div>
          <Select
            onChange={value => {
              onFilterChange('userIdentifierType', value)
            }}
            options={toConstArraySelectData(USER_IDENTIFIERS)}
            className={s.dropDown}
            placeholder="CUI / CNP"
            styles={customStyles}
            value={selectedFilters.userIdentifierType}
          />
          <Select
            onChange={value => {
              onFilterChange('status', value)
            }}
            options={toStatusesSelectData(statuses)}
            className={s.dropDown}
            placeholder="Status"
            styles={customStyles}
            value={selectedFilters.status}
          />
          <Select
            onChange={value => {
              onFilterChange('distributionCodeType', value)
            }}
            options={toConstArraySelectData(DISTRIBUTION_CODE)}
            className={s.dropDown}
            placeholder="POD / CLC"
            styles={customStyles}
            value={selectedFilters.distributionCodeType}
          />
          <div className={s.formRow}>
            <input
              type="text"
              placeholder="ID Salesforce"
              onBlur={e => onFilterChange('sf_id', e.target.value)}
            />
          </div>
        </div>
        <ul className={s.appliedFilters}>
          <li>
            <h4>Filtre aplicate:</h4>
          </li>
          {Object.keys(selectedFilters).map(
            key =>
              selectedFilters[key] &&
              shouldDisplaySelectedFilter(key) && (
                <li key={key}>
                  {renderSelectedFilter(selectedFilters[key], key)}
                  <span className={s.filterDelete} onClick={() => onFilterChange(key, '')}>
                    <FaTimes />
                  </span>
                </li>
              )
          )}
          <li className={s.appliedFiltersActions}>
            <div className={s.tblBtn} title="Sterge Filtre">
              <FiRefreshCcw
                onClick={() => {
                  availableFiltersForDisplay.forEach(key => onFilterChange(key, ''))
                }}
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default FiltersContainer
