import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'
import s from './ReportsPieChart.scss'

const COLORS = ['#01B747', '#4285f4', '#FF2E00']

const ReportsPieChart = ({ data }) => {
  return (
    <div className={s.chartContainer}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={800} height={400}>
          <Pie
            data={data}
            cx={120}
            cy={120}
            innerRadius={60}
            outerRadius={120}
            fill="#8884d8"
            paddingAngle={2}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}

export default ReportsPieChart
