export const toFilterParams = (filters, query) => {
  const {
    startDate,
    endDate,
    status,
    category,
    searchQuery,
    userIdentifierType,
    distributionCodeType,
    sf_id
  } = filters

  const result = {
    startDate,
    endDate,
    status: status?.value,
    category: category?.value,
    searchQuery,
    userIdentifierType: userIdentifierType?.value,
    distributionCodeType: distributionCodeType?.value,
    sf_id
  }

  if (query) {
    const { pageSize, page, orderBy, orderDirection } = query
    result.pageSize = pageSize
    result.page = page
    result.orderByColumn = orderBy ? orderBy.field : ''
    result.orderByValue = orderDirection
  }

  return result
}

export const toCasesTableData = cases =>
  cases.map(c => ({ ...c, name: `${c.firstName} ${c.lastName}` }))

export const toExportParams = (filters, query) => {
  const {
    startDate,
    endDate,
    status,
    category,
    searchQuery,
    userIdentifierType,
    distributionCodeType,
    sf_id
  } = filters

  const result = {
    startDate,
    endDate,
    status: status?.value,
    category: category?.value,
    searchQuery,
    userIdentifierType: userIdentifierType?.value,
    distributionCodeType: distributionCodeType?.value,
    sf_id
  }

  if (query) {
    const { orderBy, orderDirection } = query
    result.orderByColumn = orderBy ? orderBy.field : ''
    result.orderByValue = orderDirection
  }

  return result
}
