import { exportData } from './helpers'
import { makeDownloadGetRequest } from 'utils/api'
import { useLoading } from 'hooks'

const FileDownload = ({ url, params = {}, children, fileName }) => {
  const [, withLoadingLocks] = useLoading()

  const downloadFile = async () => {
    const response = await withLoadingLocks(
      makeDownloadGetRequest(
        url,
        {
          params: params
        },
        fileName
      )
    )
    exportData(response)
  }

  return <div onClick={downloadFile}>{children}</div>
}

export default FileDownload
