import { Redirect, Route } from 'react-router-dom'
import { URLS } from 'utils/constants'
import { isAuthenticated, getUserRole } from 'utils/authService'
import { NotFound } from 'components/core-ui'

const PrivateRoute = ({ component: Component, allow, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const roles = allow && allow.split(' ')
      const userRole = getUserRole()
      // if no role restriction is present on the route then everything is allowed
      const isAllowed = roles && userRole ? roles.includes(userRole) : true
      return isAuthenticated() ? (
        isAllowed ? (
          <Component {...props} key={props.location.pathname} />
        ) : (
          <NotFound />
        )
      ) : (
        <Redirect
          to={{
            pathname: URLS.LOGIN,
            state: {
              from: props.location.pathname === URLS.AUTH_LOGOUT ? URLS.AUTH_HOME : props.location
            }
          }}
        />
      )
    }}
  />
)

export default PrivateRoute
