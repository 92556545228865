import { useState, useEffect } from 'react'
import MaterialTable from '@material-table/core'
import moment from 'moment'
import { getStatusColor } from '../helpers'
import cn from 'classnames'
import { FiEdit2, FiLock, FiUnlock, FiTrash } from 'react-icons/fi'
import { UserModal } from './UserModal'
import { DeleteUserModal } from './DeleteUserModal'
import { makeGetRequest, makePatchRequest, makeDeleteRequest } from 'utils/api'
import { getUser } from 'utils/authService'
import s from './Table.scss'
import { cloneDeep } from 'lodash-es'

const columns = [
  {
    title: 'NUME',
    field: 'name',
    render: rowData => (
      <span className={cn({ [s.red]: rowData.status.name === 'blocked' })}>
        <strong>{rowData.name}</strong>
      </span>
    ),
    defaultSort: 'asc'
  },
  {
    title: 'EMAIL',
    field: 'email',
    render: rowData => (
      <a href={`mailto:${rowData.email}`} className={s.link}>
        {rowData.email}
      </a>
    )
  },
  {
    title: 'NUME UTILIZATOR',
    field: 'userName',
    render: row => row.userName
  },
  {
    title: 'ROL',
    field: 'role',
    render: row => row.role.name
  },
  {
    title: 'DATA ULIMULUI LOGIN',
    field: 'lastLoginDate',
    render: row => (row.lastLoginDate ? moment(row.lastLoginDate).format('DD.MM.YYYY, HH:mm') : '-')
  },
  {
    title: 'STATUS',
    field: 'status',
    render: rowData => (
      <span
        className={s.statusBullet}
        style={{ background: getStatusColor(rowData.status.name) }}
      />
    ),
    align: 'center',
    sorting: false
  }
]

const Table = ({
  data,
  setData,
  openUserModal,
  setOpenUserModal,
  currentUserId,
  setCurrentUserId,
  getUsers
}) => {
  const loggedUser = getUser()

  const [statuses, setStatuses] = useState([])
  const [currentDeleteUser, setCurrentDeleteUser] = useState({
    id: '',
    name: ''
  })
  const [openDeleteUserModal, setOpenDelteUserModal] = useState(false)

  useEffect(() => {
    ;(async () => {
      const result = await makeGetRequest('/api/user/status')
      setStatuses(result.rows)
    })()
  }, [])

  const changeUserStatus = async rowData => {
    const newStatusName = rowData.status?.name === 'active' ? 'blocked' : 'active'
    const newStatus = statuses.find(status => status.status === newStatusName)
    await makePatchRequest(`/api/user/${rowData.id}`, { status_id: newStatus.id })
    const newData = cloneDeep(data)
    newData.find(d => d.id === rowData.id).status = { id: newStatus.id, name: newStatus.status }
    setData(newData)
  }

  const deleteUser = async userId => {
    await makeDeleteRequest(`/api/user/${userId}`)
    await getUsers()
    setOpenDelteUserModal(false)
  }

  return (
    <div className={s.tableContainer}>
      <UserModal
        open={openUserModal}
        setOpen={setOpenUserModal}
        currentUserId={currentUserId}
        setCurrentUserId={setCurrentUserId}
        getUsers={getUsers}
      />
      <DeleteUserModal
        open={openDeleteUserModal}
        setOpen={setOpenDelteUserModal}
        user={currentDeleteUser}
        deleteUser={deleteUser}
      />
      <MaterialTable
        title=""
        columns={columns}
        data={data}
        options={{
          actionsColumnIndex: -1,
          search: false,
          headerStyle: {
            backgroundColor: '#D3D5D6',
            color: '#000',
            position: 'sticky',
            top: 0,
            zIndex: 1,
            fontWeight: 500,
            padding: '1.5rem'
          },
          maxBodyHeight: '400px',
          pageSize: 20,
          padding: 'dense',
          searchFieldStyle: {
            border: '1px solid #a7a9ab',
            borderRadius: '3px',
            backgroundColor: 'white'
          }
        }}
        localization={{
          header: {
            actions: ''
          }
        }}
        style={{
          boxShadow: 'none',
          border: '1px solid #fff',
          backgroundColor: 'rgba(211, 213, 214,0.5)'
        }}
        actions={[
          () => ({
            icon: () => (
              <span className={s.actionIcon}>
                <FiEdit2 />
              </span>
            ),
            tooltip: 'Modifica',
            onClick: (event, rowData) => {
              setCurrentUserId(rowData.id)
              setOpenUserModal(true)
            }
          }),
          rowData => ({
            icon: () => (
              <span className={cn(s.actionIcon, { [s.disabled]: loggedUser.id === rowData.id })}>
                {rowData.status.name === 'blocked' ? <FiUnlock /> : <FiLock />}
              </span>
            ),
            tooltip: rowData.status.name === 'blocked' ? 'Deblocheaza' : 'Blocheaza',
            onClick: (event, rowData) => loggedUser.id !== rowData.id && changeUserStatus(rowData)
          }),
          rowData => ({
            icon: () => (
              <span className={cn(s.actionIcon, { [s.disabled]: loggedUser.id === rowData.id })}>
                <FiTrash />
              </span>
            ),
            tooltip: 'Sterge',
            onClick: (event, rowData) => {
              if (loggedUser.id !== rowData.id) {
                setCurrentDeleteUser(rowData)
                setOpenDelteUserModal(true)
              }
            }
          })
        ]}
      />
    </div>
  )
}

export default Table
