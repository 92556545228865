import { Component } from 'react'

class OutsideClickContainer extends Component {
  wrapper
  componentDidMount() {
    if (document.body) {
      document.body.addEventListener('mousedown', this.handleClick)
    }
  }

  componentWillUnmount() {
    if (document.body) {
      document.body.removeEventListener('mousedown', this.handleClick)
    }
  }

  setWrapper = el => {
    this.wrapper = el
  }

  handleClick = event => {
    if (typeof this.props.closeComponent !== 'function') {
      return
    }
    const { target } = event
    if (this.wrapper && target instanceof HTMLElement) {
      if (target !== this.wrapper && !this.wrapper.contains(target)) {
        this.props.closeComponent()
      }
    }
  }

  render() {
    const styles = { height: '100%', ...this.props.styles }
    return (
      <div ref={this.setWrapper} style={styles}>
        {this.props.children}
      </div>
    )
  }
}

export default OutsideClickContainer
