import { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import ReCAPTCHA from 'react-google-recaptcha'
import { makePostRequest, loginRedirect } from 'utils/api'
import { authenticate } from 'utils/authService'
import { URLS, RECAPTCHA_KEY } from 'utils/constants'
import cn from 'classnames'
import s from '../Login.scss'

const LoginForm = ({ props, className, setIsLoginFormVisible }) => {
  const [recaptchaToken, setRecaptchaToken] = useState(null)
  const [error, setError] = useState('')
  const [pinState, setPinState] = useState(false)
  const [savedUsername, setUsername] = useState(null)
  const [savedPassword, setPassword] = useState(null)
  const [resendpin, setResendPin] = useState(false)

  const history = useHistory()

  const recaptchaRef = useRef()
  const resendAuthPin = async () => {
    try {
      const response = await makePostRequest('/api/auth/login', {
        username: savedUsername,
        password: savedPassword
      })

      if (!response) {
        //TODO push notification
      }
      setPinState(true)
      setError('')
    } catch (error) {
      setError('Numele de utilizator sau parola sunt incorecte.')
    }
  }

  const formSubmit = async values => {
    const { username, password, pin } = values
    if (!recaptchaToken) {
      setError('Trebuie sa bifezi reCAPTCHA')
      return null
    }
    if (!pinState || resendpin) {
      try {
        const response = await makePostRequest('/api/auth/login', {
          username: resendpin ? savedUsername : username.trim().toLowerCase(),
          password: resendpin ? savedPassword : password
        })

        if (!response) {
          //TODO push notification
        }
        setUsername(username)
        setPassword(password)
        setPinState(true)
        setError('')
      } catch (error) {
        setError('Numele de utilizator sau parola sunt incorecte.')
      }
    } else {
      try {
        const response = await makePostRequest('/api/auth/login', {
          username: savedUsername.trim().toLowerCase(),
          password: savedPassword,
          pin
        })

        if (!response) {
          //TODO push notification
        }
        authenticate(response)
        loginRedirect(history)
        setError('')
      } catch (error) {
        setError('Numele de utilizator sau parola sunt incorecte.')
      }
    }
  }

  const onRecaptchaChange = value => {
    if (value) {
      setRecaptchaToken(value)
      setError('')
    }
  }

  return (
    <>
      <a className={cn(s.logo, className)} href={URLS.LOGIN} />
      <div className={cn(s.loginBox, className)}>
        <Formik
          props={props}
          initialValues={{ username: '', password: '', pin: null, resendpin: null }}
          onSubmit={(values, { setSubmitting }) => {
            formSubmit(values).then(() => setTimeout(() => setSubmitting(false), 2000))
          }}
          validationSchema={Yup.object().shape({
            username: !pinState ? Yup.string().required('Nume utilizator este obligatoriu') : null,
            password: !pinState ? Yup.string().required('Parola este obligatorie') : null,
            pin: pinState ? Yup.string().required('Pin este obligatorie') : null
          })}
        >
          {props => {
            const { values, touched, errors, handleBlur, handleChange, handleSubmit } = props

            return (
              <div>
                {pinState ? <h3>Introdu Codul PIN</h3> : <h3>Autentificare</h3>}

                {pinState ? (
                  <p>Verifica adresa de email asociata acestui cont pentru codul de verificare</p>
                ) : (
                  ''
                )}
                <form className={s.form} onSubmit={handleSubmit}>
                  {!pinState ? (
                    <div className={s.formRow}>
                      <div className={s.formRow}>
                        <input
                          id="username"
                          type="text"
                          name="username"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.username}
                          placeholder="Nume utilizator"
                        />
                        {errors.username && touched.username && (
                          <div className={s.error}>{errors.username}</div>
                        )}
                      </div>
                      <div className={s.formRow}>
                        <input
                          id="password"
                          type="password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          placeholder="Parola"
                        />
                        {errors.password && touched.password && (
                          <div className={s.error}>{errors.password}</div>
                        )}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {pinState ? (
                    <div className={s.formRow}>
                      <input
                        id="pin"
                        type="text"
                        name="pin"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pin}
                        placeholder="PIN"
                      />
                      {errors.pin && touched.pin && <div className={s.error}>{errors.pin}</div>}
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className={s.formRow}>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={RECAPTCHA_KEY}
                      onChange={onRecaptchaChange}
                    />
                    {error && <p className={s.error}>{error}</p>}
                  </div>
                  {pinState ? (
                    <p className={s.forgotPassLink}>
                      <a
                        href=""
                        onClick={e => {
                          e.preventDefault()
                          setResendPin(true)
                          resendAuthPin()
                        }}
                      >
                        Retrimite PIN
                      </a>
                    </p>
                  ) : (
                    <p className={s.forgotPassLink}>
                      <a
                        href=""
                        onClick={e => {
                          e.preventDefault()
                          setIsLoginFormVisible(false)
                        }}
                      >
                        Ai uitat parola?
                      </a>
                    </p>
                  )}
                  <div className={s.formRow}>
                    <button className={s.button} name="resendpin" type="submit">
                      {pinState ? 'Introdu PIN' : 'Autentificare'}
                    </button>
                  </div>
                </form>
              </div>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default LoginForm
