import { TABS } from './constants'

export const getTabByUrl = location => {
  let currentTab
  switch (location.pathname) {
    case '/':
      currentTab = TABS.DASHBOARD
      break
    case '/reports':
      currentTab = TABS.REPORTS
      break
    case '/admin':
      currentTab = TABS.ADMIN
      break
    case '/profile':
      currentTab = TABS.ME
      break
    default:
      currentTab = TABS.DASHBOARD
      break
  }

  return currentTab
}
