import { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { AiOutlineDoubleRight } from 'react-icons/ai'
import { CaseDetailsLeft } from './CaseDetailsLeft'
import { CaseDetailsRight } from './CaseDetailsRight'
import { makeGetRequest } from 'utils/api'
import { URLS } from 'utils/constants'
import s from './Case.scss'

const initialCase = {
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  distributionCode: {},
  userIdentifier: {},
  category: {},
  agent: {},
  sf_id: '',
  status: {},
  data: '',
  title: '',
  body: '',
  attachments: []
}

const Case = () => {
  const [caseData, setCaseData] = useState(initialCase)

  const { id } = useParams()

  useEffect(() => {
    ;(async () => {
      const result = await makeGetRequest(`/api/case/${id}`)
      setCaseData(result)
    })()
  }, [id])

  const history = useHistory()

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href=""
      onClick={() => history.push(URLS.AUTH_HOME)}
    >
      DASHBOARD
    </Link>,
    <Typography key="3" color="text.primary">
      CAZ#{caseData.id}
    </Typography>
  ]

  return (
    <div className={s.contentBoxBody}>
      <div className={s.breadcrumbs}>
        <Breadcrumbs separator={<AiOutlineDoubleRight />} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </div>
      <article className={s.caseBox}>
        <CaseDetailsLeft caseData={caseData} />
        <CaseDetailsRight caseData={caseData} />
      </article>
    </div>
  )
}

export default Case
