export const URLS = {
  AUTH_HOME: '/',
  BASE_PATH: '/agent',
  LOGIN: '/login',
  AUTH_CASE: '/case/:id',
  AUTH_REPORTS: '/reports',
  AUTH_ADMIN: '/admin',
  AUTH_PROFILE: '/profile'
}

export const URL = {
  development:
    'http://internal-ae0a48d9aee824da3869f56817015ab9-1438929207.eu-central-1.elb.amazonaws.com', // 'http://internal-a78bb00ecf17d4f3d9cbeaf5f8231109-1841137995.eu-central-1.elb.amazonaws.com', // ,
  uat: 'http://internal-ae0a48d9aee824da3869f56817015ab9-1438929207.eu-central-1.elb.amazonaws.com',
  production: 'https://sep.ppcenergy.ro'
}

export const RECAPTCHA_KEY = '6LdOtiMeAAAAAKRqfeLTgZsxwh7U2VydC4oQ4NW4'

export const TIME_SPANS = [
  {
    value: '24h',
    label: '24h'
  },
  {
    value: '7d',
    label: '7d',
    default: true
  },
  {
    value: '1m',
    label: '1m'
  },
  {
    value: '3m',
    label: '3m'
  },
  {
    value: '1y',
    label: '1y'
  },
  {
    value: 'all',
    label: 'All time'
  }
]
