import { useHistory } from 'react-router-dom'
import { FaTimes } from 'react-icons/fa'
import { RiDashboardLine } from 'react-icons/ri'
import { AiOutlinePieChart, AiOutlinePoweroff } from 'react-icons/ai'
import { FiUsers, FiUser } from 'react-icons/fi'
import { FaArrowRight } from 'react-icons/fa'
import cn from 'classnames'
import { URLS } from 'utils/constants'
import { logout, getUser, isAdmin } from 'utils/authService'
import { TABS } from '../constants'
import s from './HeaderMenu.scss'

const HeaderMenu = ({ isVisible, closeMenu, setActiveTab, activeTab }) => {
  const history = useHistory()

  const currentUser = getUser()

  return (
    <div className={cn({ [s.visible]: isVisible }, s.menuContainer)}>
      <div className={s.headerContainer}>
        <div className={s.logoWrapper}>
          <a className={s.logo} href="#" />
        </div>
        <div />
        <div className={s.close}>
          <p>Inchide</p>
          <span onClick={closeMenu}>
            <FaTimes className={s.closeIcon} />
          </span>
        </div>
      </div>
      <div className={s.content}>
        <div className={s.greeting}>
          Bine ai venit, {currentUser.firstName} {currentUser.lastName}!
        </div>
        <div className={s.menu}>
          <div
            onClick={() => {
              setActiveTab(TABS.DASHBOARD)
              closeMenu()
              history.push(URLS.AUTH_HOME)
            }}
            className={cn(s.link, { [s.active]: activeTab === TABS.DASHBOARD })}
          >
            <RiDashboardLine />
            Dashboard
            <FaArrowRight className={s.arrow} />
          </div>
          <div
            onClick={() => {
              setActiveTab(TABS.REPORTS)
              closeMenu()
              history.push(URLS.AUTH_REPORTS)
            }}
            className={cn(s.link, { [s.active]: activeTab === TABS.REPORTS })}
          >
            <AiOutlinePieChart />
            Analize si rapoarte
            <FaArrowRight className={s.arrow} />
          </div>
          {isAdmin() && (
            <>
              <div
                onClick={() => {
                  setActiveTab(TABS.ADMIN)
                  closeMenu()
                  history.push(URLS.AUTH_ADMIN)
                }}
                className={cn(s.link, { [s.active]: activeTab === TABS.ADMIN })}
              >
                <FiUsers />
                Administrare
                <FaArrowRight className={s.arrow} />
              </div>
            </>
          )}
          <div
            onClick={() => {
              setActiveTab(TABS.ME)
              closeMenu()
              history.push(URLS.AUTH_PROFILE)
            }}
            className={cn(s.link, { [s.active]: activeTab === TABS.ME })}
          >
            <FiUser />
            Contul meu
            <FaArrowRight className={s.arrow} />
          </div>
          {!isAdmin() && <div />}
          <div
            to="/"
            onClick={() => {
              logout()
              history.push(URLS.LOGIN)
            }}
            className={cn(s.link, s.logout)}
          >
            <AiOutlinePoweroff />
            Logout
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderMenu
