import moment from 'moment'
import { AiOutlinePaperClip } from 'react-icons/ai'
import { BiImport } from 'react-icons/bi'
import parse from 'html-react-parser'
import { FileDownload } from 'components/common/FileDownload'
import { handleLineBreak, sanitaizeHtmlCode } from 'utils/helpers'
import s from './CaseDetailsLeft.scss'

const CaseDetailsLeft = ({ caseData }) => {
  return (
    <div className={s.caseContent}>
      <p className={s.caseData}>{moment(caseData.data).format('DD.MM.YYYY, HH:mm')}</p>
      <h2 className={s.caseName}>
        {caseData.firstName} {caseData.lastName}
      </h2>
      {/* <p className={s.caseEmail}>
        <a href={`mailto:${caseData.email}`} className={s.link}>
          {caseData.email}
        </a>
      </p> */}
      <h3 className={s.caseTitle}>{caseData.title}</h3>
      <p className={s.caseEmailData}>{caseData.email.from}</p>
      <p className={s.caseEmailData}>{caseData.email.to}</p>
      <p className={s.caseEmailData}>{caseData.email.sent}</p>
      <p className={s.caseMsg}>
        {parse(sanitaizeHtmlCode(handleLineBreak(caseData.email.body || '')))}
      </p>
      {!!caseData.attachments.length && (
        <div className={s.caseAttachments}>
          <h4>
            <AiOutlinePaperClip />
            Atasamente
          </h4>
          <ul>
            {caseData.attachments.map(attachment => (
              <li key={attachment.id}>
                <strong title={attachment.type}>{attachment.type}</strong>
                <span title={attachment.name}>{attachment.name}</span>
                <FileDownload
                  url={`/api/case/attachment/${attachment.id}`}
                  fileName={`${attachment.name}${
                    attachment.type != null && attachment.type != '' ? '.' + attachment.type : ''
                  }`}
                >
                  <span className={s.exportBtn}>
                    <BiImport />
                  </span>
                </FileDownload>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default CaseDetailsLeft
