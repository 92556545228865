import { useState, useEffect } from 'react'
import { BiExport } from 'react-icons/bi'
import { TimeSpanSelector } from './TimeSpanSelector'
import { RangeDatePicker } from 'components/core-ui/RangeDatePicker'
import { FiltersToggler } from './FiltersToggler'
import { FiltersContainer } from './FiltersContainer'
import { Search } from 'components/common/Search'
import { makeGetRequest } from 'utils/api'
import { FileDownload } from 'components/common/FileDownload'
import s from './Filters.scss'

const Filters = ({
  selectedFilters,
  onFilterChange,
  onFilterRangePickerChange,
  searchItems,
  exportUrl,
  exportParams
}) => {
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [categories, setCategories] = useState([])
  const [statuses, setStatuses] = useState([])

  useEffect(() => {
    ;(async () => {
      const response = await makeGetRequest('/api//category')
      setCategories(response)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      const response = await makeGetRequest('/api/classification_ticket/statuses')
      setStatuses(response)
    })()
  }, [])

  const toggleFilters = () => setFiltersVisible(!filtersVisible)

  return (
    <div>
      <div className={s.filtersContainer}>
        <div>
          <TimeSpanSelector selected={selectedFilters.timeSpan} onFilterChange={onFilterChange} />
          <RangeDatePicker
            startDate={selectedFilters.startDate}
            endDate={selectedFilters.endDate}
            onChange={onFilterRangePickerChange}
          />
          <FiltersToggler filtersVisible={filtersVisible} toggleFilters={toggleFilters} />
        </div>
        <div className={s.searchContainer}>
          <Search searchItems={searchItems} />
          <FileDownload
            url={exportUrl}
            params={exportParams}
            fileName={`report_${selectedFilters.startDate ? selectedFilters.startDate : 'all'}_${
              selectedFilters.endDate ? selectedFilters.endDate : 'time'
            }.xlsx`}
          >
            <span className={s.exportBtn}>
              <BiExport />
              Exporta
            </span>
          </FileDownload>
        </div>
      </div>
      <FiltersContainer
        categories={categories}
        statuses={statuses}
        isVisible={filtersVisible}
        selectedFilters={selectedFilters}
        onFilterChange={onFilterChange}
      />
    </div>
  )
}

export default Filters
