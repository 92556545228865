import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import s from './DeleteUserModal.scss'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
}

const DeleteUserModal = ({ open, setOpen, user, deleteUser }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={s.userModalContent}>
            <div>Esti sigur ca vrei sa stergi utilizatorul {user.name}?</div>
            <button className={s.submitBtn} onClick={() => deleteUser(user.id)}>
              Sterge utilizator
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default DeleteUserModal
